import React, { useContext, useEffect, useState } from "react";
import useApi from '../Hooks/useApi';
import Metatags from '../Components/metas-tags/meta-tags';
import { useDispatch } from 'react-redux';
import { updateGlobalStorePage } from '../Store/action';
import { SoundPlayerIntro } from "../modules/sound-player-intro/sound-player-intro";
import CanvasPlayer from "../Components/CanvasPlayer";
import CLink from "../Components/cLinks";
import LoadPage from "../Components/loaders/loader";
import { SoundBackgroundContext } from '../modules/sound-background/sound-background';
import Sound from "../tools/Sound";
import { Link } from "react-router-dom";

const TemplateIntro = ({ _uid }) => {

    const [dataFetch, isLoaded] = useApi({ name: "pages", _uid });
    const { metas, content } = dataFetch;

    const [srtOpen, setSrtOpen] = useState(false);
    const [currentLine, setCurrentLine] = useState(0);
    const [srt, setSrt] = useState([]);
    const [time, setTime] = useState(0);

    useEffect(() => {
        if (content && content.main.sous_titrage) {
            Sound.loadIntro(content.main.sous_titrage, item => setSrt(item));
        }
    }, [content]);

    let dispatch = useDispatch();
    useEffect(() => {
        dispatch(updateGlobalStorePage('content-page'));
    }, [dispatch, isLoaded]); // isLoaded

    const { stop } = useContext(SoundBackgroundContext)
    useEffect(() => {
        stop();
    }, []);

    const getSRT = (tab, id) => {
        if (tab.length > 0 && id !== null) {
            if (tab[id]) {
                return tab[id].srt.length;
            }
        }

        return 0;
    }

    const updateTime = info => setTime(info);

    useEffect(() => {
        let nextLine = null;

        srt.forEach((item, index) => {
            if (nextLine === null) {
                let start = Sound.convertTimecodeToSeconds(item.startTime);
                let end = Sound.convertTimecodeToSeconds(item.endTime);

                if (isNaN(start) !== "true" && isNaN(end) !== "true") {
                    if (start <= time && end >= time) {
                        nextLine = index;
                    } else if (start > time && index > 0) {
                        nextLine = index - 1;
                    }
                }
            }
        })

        if (nextLine === null) {
            nextLine = srt.length;
        }

        setCurrentLine(nextLine);
    }, [time, srt]);

    const renderPage = () => (
        <>
            {metas && <Metatags {...metas} />}

            {
                content && <div className="headerPageIntro">
                    <div className="headerPageIntro_inner layout2">
                        <div className="headerPageIntro_logo">
                            {
                                content.main.home_page && <Link to={content.main.home_page}><img src={content.top.general.logo.image.src} alt={content.top.general.logo.image.alt} /></Link>
                            }
                            {
                                !content.main.home_page && <img src={content.top.general.logo.image.src} alt={content.top.general.logo.image.alt} />
                            }

                            <SoundPlayerIntro className="headerPageIntro_soundPlayer" file={content.main.audio.src} timeUpdate={time => updateTime(time)}></SoundPlayerIntro>
                        </div>
                        {
                            !srtOpen && <div className="headerPageIntro_animation">
                                {content.main.gif.mime_type === "video/mp4"
                                    ? <CanvasPlayer videoSrc={content.main.gif}/>
                                    : <img src={content.main.gif.src} alt={content.main.gif.alt} />
                                }
                            </div>
                        }
                        {
                            srtOpen && (
                                <div className="headerPageIntro_srt_srt">
                                    {
                                        srt.map((item, index) => {
                                            return (
                                                <>
                                                    {
                                                        currentLine === 0 && index === 0 && <><p>&nbsp;</p><p>&nbsp;</p></>
                                                    }
                                                    {
                                                        currentLine === 1 && index === 0 && <><p>&nbsp;</p></>
                                                    }
                                                    <p key={index} className={`headerPageIntro_srt_srt_line headerPageIntro_srt_srt_line--diff${Math.abs(index - currentLine)}`}>{item.text}</p>
                                                </>
                                            )
                                        })
                                    }
                                </div>
                            )
                        }
                        <p className="headerPageIntro_button">
                            <CLink {...content.main.cta} className="button button--type2 button--type2--noArrow" />
                            <span className="headerPageIntro_srt_box">
                                {
                                    srt.length > 0 && <button
                                        onClick={e => setSrtOpen(!srtOpen)}
                                        className={`headerPageIntro_srt_button2 ${srtOpen ? "headerPageIntro_srt_button2--current" : ""} headerPageIntro_srt_button--srt`}>
                                        <span className="headerPageIntro_srt_button2_top"></span>
                                        <span className="headerPageIntro_srt_button2_middle"></span>
                                        <span className="headerPageIntro_srt_button2_bottom"></span>
                                    </button>
                                }
                            </span>
                        </p>
                    </div>
                </div>
            }
        </>
    )

    return isLoaded ? renderPage() : <LoadPage />
}

export default TemplateIntro;