import React, { useEffect, useContext } from "react";
import useApi from '../Hooks/useApi';
import Metatags from '../Components/metas-tags/meta-tags';
import { useDispatch } from 'react-redux';
import { updateGlobalStorePage } from '../Store/action';
import { Link } from "react-router-dom";
import Sound from '../tools/Sound'
import LoadPage from "../Components/loaders/loader";

import { SoundBackgroundContext } from '../modules/sound-background/sound-background';
import { SoundInterface } from "../modules/sound-interface/sound-interface";

const TemplateCollection = ({ _uid }) => {

    const [dataFetch, isLoaded] = useApi({ name: "pages", _uid });
    const { metas, content } = dataFetch;
    const { start, currentTrack, playing, setLoop, stop } = useContext(SoundBackgroundContext)

    let dispatch = useDispatch();
    useEffect(() => {
        dispatch(updateGlobalStorePage('home'));
    }, [dispatch, isLoaded]);

    const playAll = (tab) => {
        setLoop(true);
        Sound.loadFiles(tab, finalTab => start(finalTab));
    }

    const play = (tab, index) => {
        setLoop(false);
        Sound.loadFiles(tab, finalTab => start(finalTab, index));
    }

    useEffect(() => {
        stop();
    }, []);

    const renderPage = () => (
        <>
            {metas && <Metatags {...metas} />}

            {
                content && <>
                    <div className="headerPageCollection" style={{ background: "linear-gradient(#010000, " + content.main.collection_couleurs.color_gradient + " 35%, " + content.main.collection_couleurs.color + ")" }}>
                        <SoundInterface appearsOnPlaying={true} className={"headerPageCollection_soundInterface"} color={content.main.collection_couleurs.color} colorGradient={content.main.collection_couleurs.color_gradient} visuel={"/assets/img/visuel_micro.png"} />
                        <div className="headerPageCollection_inner layout2">
                            <div className="headerPageCollection_logo">
                                {
                                    content.main.collection_retour_home && <Link to={content.main.collection_retour_home}><img src="/assets/img/logo_transfo.svg" alt="Les voies de Transfo" /></Link>
                                }
                                {
                                    !content.main.collection_retour_home && <img src="/assets/img/logo_transfo.svg" alt="Les voies de Transfo" />
                                }
                            </div>
                        </div>
                    </div>

                    <div className="playlist">
                        <div className="layout">
                            <div className="playlist_intro">
                                <div className="playlist_back">
                                    {
                                        content.main.collection_retour_home && <Link className="button--back" to={content.main.collection_retour_home}></Link>
                                    }
                                </div>
                                <div className="playlist_infos">
                                    <p className="playlist_titre">{content.main.collection_titre}</p>
                                    <p>
                                        <span className="playlist_nombres">{content.main.collection_nb_listes}</span>
                                        <span className="playlist_duree"> - {Sound.getAllDuration(content.main.lst_capsules)}</span>
                                    </p>
                                </div>
                                {/* {
                                    !playing && <button className="playlist_startAll button button--type3" onClick={() => playAll(content.main.lst_capsules)}>{content.main.collection_tout}</button>
                                } */}
                                <button className={`playlist_startAll button button--type3 ${playing ? "playlist_startAll--playing" : ""}`} onClick={() => playAll(content.main.lst_capsules)}>{content.main.collection_tout}</button>
                            </div>
                            <div className="playlist_chapeau">{content.main.collection_description}</div>
                            <div className="playlist_contenus">
                                <table>
                                    <thead>
                                        <tr>
                                            <th className="playlist_contenus_label">{content.main.colonnes_lst.col1}</th>
                                            <th className="playlist_contenus_label">{content.main.colonnes_lst.col2}</th>
                                            <th className="playlist_contenus_label playlist_contenus_label--duree"><img src="/assets/img/icon_time.png" alt="Durée" /></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            content.main.lst_capsules.map((item, index, tab) => {
                                                return (
                                                    <tr key={index} className={`playlist_contenus_item ${index === currentTrack ? "playlist_contenus_item--current" : ""}`}>
                                                        <td className="playlist_contenus_itemIndex"><strong>{index + 1}</strong>/{tab.length}</td>
                                                        <td className="playlist_contenus_itemTitre"><Link to={item.path} onClick={() => play(content.main.lst_capsules, index)} className="playlist_contenus_itemButton"><div className="playlist_contenus_itemVisuel" style={{ background: "linear-gradient(" + content.main.collection_couleurs.color + ", " + content.main.collection_couleurs.color_gradient + ")" }} ><div className="playlist_contenus_itemVisuelBox"><img src={item.image.src} alt={item.image.alt} /></div></div><p className="playlist_contenus_itemLabel"><strong>Capsule audio {index + 1}/{tab.length}</strong>{item.title}</p></Link></td>
                                                        <td className="playlist_contenus_itemDuree"><span>{Sound.getDuration(item.duree)}</span></td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    )

    return isLoaded ? renderPage() : <LoadPage />
}

export default TemplateCollection;