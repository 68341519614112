import React, { useState } from 'react'

const Description = ({ chapeau = "", label = "", labelclose = "", html = "" }) => {

    const [isDescriptionVisible, setDescriptionVisible] = useState(false);

    return (
        <div className="description">
            <div className="layout5">
                <p className="description_texte">{chapeau}</p>
                <div className={"description_more " + (isDescriptionVisible ? "description_more--visible" : "")}>
                    <div className='description_more_inner'>
                        <p dangerouslySetInnerHTML={{ __html: html }}></p>
                    </div>
                </div>
                <p className={"description_link"}><button onClick={() => setDescriptionVisible(!isDescriptionVisible)}>{isDescriptionVisible ? labelclose : label}</button></p>
            </div>
        </div>
    )

}
export default Description;