import React, { useContext, useRef, useState } from "react";
import YouTube from 'react-youtube';
import { SoundBackgroundContext } from '../../modules/sound-background/sound-background';

const Video = ({ image_couverture, file, code_youtube, player }) => {

    const [isStarted, setStarted] = useState(false);
    const [youtubePlayer, setYoutubePlayer] = useState(null);
    const video = useRef(null);
    const youtube = useRef(null);
    const { play, pause } = useContext(SoundBackgroundContext)

    const startVideo = (type) => {
        if (video.current && type === "mp4") {
            setStarted(true);
            video.current.play();
        } else if (youtube.current && type === "youtube" && youtubePlayer) {
            setStarted(true);
            youtubePlayer.target.playVideo();
        }
    }

    const youtubeReady = (e) => {
        setYoutubePlayer(e);
    }

    const pausing = () => {
        play();
    }

    const playing = () => {
        pause();
    }

    const interfaceState = (e) => {
        if (e.data === 1) {
            playing();
        } else if (e.data === 0 || e.data === 2) {
            pausing();
        }
    }

    if(!window.tarteaucitron.state.youtubeapi){
        return null;
    }
    
    return (
        <div className="flex_video">
            <div className="flex_video_bloc">
                {
                    player === "mp4" ?
                        (
                            <video ref={video} src={file.src} controls onPause={() => pausing()} onPlay={() => playing()}></video>
                        ) : (
                            <div className="flex_video_youtube">
                                <YouTube ref={youtube} videoId={code_youtube} onReady={e => youtubeReady(e)} onStateChange={e => interfaceState(e)} />
                            </div>
                        )
                }
                {
                    !isStarted && <>
                        <img src={image_couverture.src} alt={image_couverture.alt} />
                        <button onClick={() => startVideo(player)}></button>
                    </>
                }
            </div>
        </div>
    )
}
export default Video;