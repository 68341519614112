import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import CLink from './link';

const Navigation = () => {

    const [menuOpen, toggleMenu] = useState(false);
    const [currentListe, setCurrentListe] = useState("");

    const changeUrl = (e) => {
        let link = e.target;

        if(window.innerWidth >= 1024){
            toggleMenu(false);
        }

        if (link.classList.contains("forcedLink") || window.innerWidth >= 1024) {
            link.classList.remove("forcedLink")
        } else {
            e.preventDefault();
            link.classList.add("forcedLink")

            setTimeout(() => {
                toggleMenu(false);
                link.click();
            }, 500);
        }
    }

    const closeMenu = (e) => {
        if (e.target.classList.contains("navigation")) {
            toggleMenu(false);
        }
    }

    let dataGlobal = useSelector(state => state.global).global;

    return (
        <>
            {
                dataGlobal?.header?.menu && dataGlobal?.header?.menu.length > 0 && <div className={"navigation red " + (menuOpen ? "navigation--visible" : "")} onClick={e => closeMenu(e)}>
                    <CLink className={"navigation_logo"} internal={true} url="/"><img src="/assets/img/logo3.png" alt="" /></CLink>
                    <button className="navigation_toggle" onClick={() => toggleMenu(!menuOpen)}>Menu</button>
                    <nav className='navigation_content'>
                        <ul className="navigation_firstLevel_liste">
                            {
                                dataGlobal?.header?.menu.map((item1, index1) => {
                                    return (
                                        <li key={"menu_" + index1} className="navigation_firstLevel_item">
                                            {
                                                item1.children.length === 0 && <CLink className={"navigation_firstLevel_cta navigation_lien2 " + (item1.header_couleur_theme !== "" ? item1.header_couleur_theme : "")} handleClick={e => changeUrl(e, item1.url)} url={item1.url} target={item1.target} internal={item1.internal}>{item1.title}</CLink>
                                            }
                                            {
                                                item1.children.length > 0 && <>
                                                    <button onClick={() => setCurrentListe(currentListe === "menu_" + index1 ? "" : "menu_" + index1)} className={"navigation_firstLevel_cta navigation_firstLevel_cta--button " + (currentListe === "menu_" + index1 ? "navigation_firstLevel_cta--button--selected" : "")}>{item1.title}</button>
                                                    <ul className={"navigation_secondLevel_liste " + (item1.header_couleur_theme !== "" ? item1.header_couleur_theme : "") + " " + (currentListe === "menu_" + index1 ? "navigation_secondLevel_liste--visible" : "")}>
                                                        {
                                                            item1.children.map((item2, index2) => <li key={"menu_" + index1 + "_" + index2} className="navigation_secondLevel_item"><CLink className={"navigation_secondLevel_cta navigation_lien " + (item2.header_couleur_theme !== "" ? item2.header_couleur_theme : "")} handleClick={e => changeUrl(e, item2.url)} url={item2.url} target={item1.target} internal={item1.internal}>{item2.title}</CLink></li>)
                                                        }
                                                    </ul>
                                                </>
                                            }
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </nav>
                </div>
            }
        </>
    )

}
export default Navigation;