import React, { useEffect, useRef, useState } from "react";
import "./sound-player-intro.scss";

export const SoundPlayerIntro = ({ file, className, timeUpdate }) => {
	const refCanvas = useRef(null);
	const [timeLeft, setTimeLeft] = useState(0);
	const [sound, setSound] = useState(null);

	const strokeArc = (size, posY, canvas, context, ratio, start, end, condition) => {
		context.strokeStyle = "rgba(255,255,255," + (condition ? 1 : 0.5) + ")";
		context.beginPath();
		context.arc(size, size * posY, canvas.width * ratio, Math.PI * start, Math.PI * end);
		context.stroke();
		context.closePath();
	};

	const draw = (canvas, t) => {
		let parentWidth = canvas.parent.getBoundingClientRect().width;
		canvas.width = parentWidth;
		canvas.height = parentWidth;

		let size = canvas.width * 0.5;
		let lineWidth = 3;
		let context = canvas.getContext("2d");
		context.lineWidth = lineWidth;
		context.strokeStyle = "#FFFFFF";

		context.beginPath();
		context.fillStyle = "rgba(0,0,0,0.5)";
		context.arc(size, size, size - lineWidth, 0, Math.PI * 2);
		context.fill();
		context.closePath();

		if (sound) {
			const timeLeft = sound.duration - sound.currentTime;
			setTimeLeft(isNaN(timeLeft) ? 0 : timeLeft);
			timeUpdate(sound.currentTime);

			let duration = (100 * sound.currentTime) / sound.duration;
			let radius = duration / 50 - 0.5;
			context.beginPath();
			//context.arc(size, size, (canvas.width - lineWidth) * 0.5, -Math.PI * 0.5, Math.PI * radius);
			context.arc(size, size, (canvas.width - lineWidth) * 0.5, Math.PI * radius, Math.PI * 1.5);
			context.stroke();
			context.closePath();
		}

		let sizeSmallRatio = 0.23;
		let sizeMiddleRatio = 0.31;

		let isFirstCircle = t % 1000 > 250;
		let isSecondCircle = t % 1000 > 500;

		strokeArc(size, 0.93, canvas, context, sizeSmallRatio, -0.15, 0.15, sound && !sound.paused && isFirstCircle);
		strokeArc(size, 0.93, canvas, context, sizeSmallRatio, 0.85, 1.15, sound && !sound.paused && isFirstCircle);
		strokeArc(size, 0.93, canvas, context, sizeMiddleRatio, -0.15, 0.15, sound && !sound.paused && isSecondCircle);
		strokeArc(size, 0.93, canvas, context, sizeMiddleRatio, 0.85, 1.15, sound && !sound.paused && isSecondCircle);
	};

	useEffect(() => {
		let canvas = refCanvas.current;
		canvas.parent = canvas.parentElement;
		let animationFrameId;

		const render = t => {
			draw(canvas, t);
			animationFrameId = requestAnimationFrame(render);
		};
		render(0);

		return () => {
			cancelAnimationFrame(animationFrameId);
		};
	}, [sound]);

	useEffect(() => {
		playAudio(sound);
	}, [sound]);

	const playAudio = () => {
		if (sound) {
			let promise = sound.play();
			if (promise !== undefined) {
				promise.then(() => {}).catch(error => {});
			}
		}
	};

	const toggleAudio = () => {
		if (sound) {
			sound[sound.paused ? "play" : "pause"]();
		}
	};

	// const interfaceCurrentTime = () => {
	//     if (sound) {
	//         console.log("test");
	//         setCurrentTime(sound.currentTime);
	//         timeUpdate(sound.currentTime);
	//     }
	// }

	useEffect(() => {
		let audio = new Audio(file);
		//audio.addEventListener("timeupdate", interfaceCurrentTime)
		setSound(audio);

		// return () => {
		//     audio.removeEventListener("timeupdate", interfaceCurrentTime)
		// }
	}, [file]);

	useEffect(() => {
		return () => {
			if (sound) {
				sound.pause();
			}
		};
	}, [sound]);

	const normaliseTime = time => {
		let minute = parseInt(time / 60);
		let minuteStr = "" + minute;
		let second = parseInt(time) % 60;
		let secondStr = second < 10 ? "0" + second : "" + second;

		return minuteStr + ":" + secondStr;
	};

	return (
		<div className={className}>
			<div className={`soundPlayerIntro`} onClick={e => toggleAudio()}>
				<canvas ref={refCanvas}></canvas>
				<div className="soundPlayerIntro__micro"></div>
				<p className="soundPlayerIntro__timer">{normaliseTime(timeLeft)}</p>
			</div>
		</div>
	);
};
