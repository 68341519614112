import React from "react";
import {Link} from "react-router-dom";

const CLink = ({children, className, url, title, target, internal, handleClick, partner = ''}) => {
    return (
        <>
            {internal ?
                <Link to={`${url}${partner !== ('' || null) && partner}`} title={title}
                      target={target ? target : '_self'} className={className}
                      onClick={handleClick}>{children ? children : title}</Link>
                :
                <a href={`${url}${partner !== ('' || null) && partner}`} title={title}
                   target={target ? target : '_self'} className={className}
                   onClick={handleClick}>{children ? children : title}</a>
            }
        </>
    )
};

export default CLink;