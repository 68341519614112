import React, {useRef} from 'react'

import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';

import Sound from "../../tools/Sound";
// Import Swiper styles
import 'swiper/swiper.scss';
import "swiper/modules/navigation/navigation.scss";


const CollectionCard = ({ collection_number, lst_capsules, url, couleurs: {color, color_gradient}, name }) => {

    const prevRef = useRef(null);
    const nextRef = useRef(null);

    return (
        <li className="c-collection-cards">
            {
                url && 
                <Link to={url}>
                    <h2 className="c-collection-cards-title">
                        Collection&nbsp;{collection_number}&nbsp;<span style={{color: color}}>{name}</span>
                    </h2>
                </Link>
            }
            {
                lst_capsules && lst_capsules.length > 0 &&
                <div className="c-collection-cards-list">
                    <div className="swiper-button prev" ref={prevRef}></div>
                    <Swiper
                        spaceBetween={20}
                        slidesPerView={2}
                        modules={[Navigation]}
                        breakpoints={{
                            640: {
                              slidesPerView: 3,
                              spaceBetween: 20,
                            },
                            991: {
                              slidesPerView: 4,
                              spaceBetween: 25,
                            },
                          }}
                        navigation={{
                            prevEl: prevRef.current,
                            nextEl: nextRef.current
                        }}>
                        {
                            lst_capsules.map((capsule, key) => (
                                <SwiperSlide key={key}>
                                    <Link to={capsule.path ? capsule.path : "#"}>
                                        {
                                            capsule.image_transparent.src && 
                                            <div className="c-collection-cards-img" style={{ backgroundImage: `linear-gradient(4deg, ${color_gradient} 2%, ${color} 96%)` }}>
                                                <img src={capsule.image_transparent.src} alt={capsule.image_transparent.alt} />
                                            </div>
                                        }
                                        {
                                            capsule.duree && 
                                            <p className="c-collection-cards-duration" style={{color: color}}><img src="/assets/img/icon_time.png" alt="Durée" />{Sound.getDuration(capsule.duree)}</p>
                                        }
                                        {
                                            capsule.title &&
                                            <p className="c-collection-cards-text">{capsule.title}</p>
                                        }
                                    </Link>
                                </SwiperSlide>    
                            ))
                        }
                    </Swiper>
                    <div className="swiper-button next" ref={nextRef}></div>
                </div>
            }
        </li>
    )
}
export default CollectionCard;