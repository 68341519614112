import React, { useState } from 'react'

const Accordeon = ({ titre = "", accordeon = [] }) => {

    const [currentMenu, setMenu] = useState(accordeon.length > 0 ? accordeon[0].titre : "");
    const [currentAccordeon, setAccordeon] = useState("0_0");

    const changeAccordeon = (index1, index2) => {
        setAccordeon(currentAccordeon === index1 + "_" + index2 ? "" : index1 + "_" + index2)
    }

    const finishTransition = (e, index1, index2) => {
        if(e.target.classList.contains("accordeon_resultat_item--selected"))
        {
            let item = e.target;
            if(item.getBoundingClientRect().top < 0){
                document.documentElement.scrollTop += item.getBoundingClientRect().top;
            }
        }
    }

    return (
        <div className="accordeon">
            <div className={"accordeon_intro " + (accordeon.length <= 1 ? "accordeon_intro--nomargin" : "")}>
                <h2 className="accordeon_titre">{titre}</h2>
                {
                    accordeon.length > 1 && <ul className="accordeon_liste">
                        {
                            accordeon.map((item, index) => <li key={"menu" + index} className="accordeon_item"><button className={currentMenu === item.titre ? "selected" : ""} onClick={() => setMenu(item.titre)}>{item.titre}</button></li>)
                        }
                    </ul>
                }
            </div>
            {
                accordeon.map((item, index1) => {
                    return (
                        currentMenu === item.titre &&
                        <ul key={"liste" + index1} className="accordeon_resultat_liste">
                            {
                                item.accordeon.map((content, index2) => {
                                    return (
                                        <li id={"item_" + index1 + "_" + index2} onTransitionEnd={e => finishTransition(e, index1, index2)} className={"accordeon_resultat_item " + (currentAccordeon === index1 + "_" + index2 ? "accordeon_resultat_item--selected" : "")} key={"item_" + index1 + "_" + index2}>
                                            <div className="layout5">
                                                <h3 className="accordeon_resultat_toggle" onClick={() => changeAccordeon(index1, index2)}>
                                                    <button className="accordeon_resultat_titre">{content.titre}</button>
                                                </h3>
                                                <div className="accordeon_resultat_content">
                                                    <div className='accordeon_resultat_content_inner'>
                                                        {
                                                            content.image && <div className="accordeon_resultat_col accordeon_resultat_col_visuel"><img src={content.image.src} alt={content.image.alt} /></div>
                                                        }
                                                        <div className="accordeon_resultat_col accordeon_resultat_col_wysiwyg" dangerouslySetInnerHTML={{ __html: content.texte_wysiwyg }}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    )
                })
            }

        </div>
    )

}
export default Accordeon;