import React from 'react';

import { useSelector } from 'react-redux';
import CLink from "../../Components/cLinks";

const Footer = () => {

	let dataGlobal = useSelector(state => state.global).global;

	const openLink = () => window.tarteaucitron.userInterface.openPanel();


	// const { logo_footer, menu } = dataGlobal.footer;
	return (
		<footer className="footer">
			{
				dataGlobal.footer &&
				<>
					<div className="footer_inner">
						<ul className="footer_logos layout">
							{
								dataGlobal.footer.logo_footer.logos &&
								dataGlobal.footer.logo_footer.logos.filter(logo => logo).map((logo, key) => {
									return (
										<li key={key}>
											<img src={logo.src} alt={logo.alt} />
										</li>
									)
								})
							}
						</ul>
					</div>
					<div className="footer_inner">
						<ul className="footer_links layout">
							{
								dataGlobal.footer.menu.main &&
								dataGlobal.footer.menu.main.map((item, key) => {
									return (
										<li key={key}>
											<CLink url={item.url} internal={item.internal} target={item.target} onClick={item.url === "#rgpd" ? () => openLink() : null}>{item.title}</CLink>
										</li>
									)
								})
							}
						</ul>
					</div>
				</>
			}
		</footer>
	)
}
export default Footer;