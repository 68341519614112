import React from 'react'
import CLink from './link';

const ListeArticles = ({ articles = [] }) => {

    return (
        <>
            {
                articles && articles.length > 0 && <div className="listeArticles">
                    <ul className="listeArticles_liste">
                        {
                            articles.map((item, index) => {
                                return (
                                    <li key={index} className="listeArticles_item">
                                        <CLink className="listeArticles_container layout5" url={item.cta.url} target={item.cta.target} internal={item.cta.internal}>
                                            {
                                                item.image && <div className="listeArticles_visuel"><img src={item.image.src} alt={item.image.alt} className="listeArticles_visuel" /></div>
                                            }
                                            <div className={"listeArticles_content " + (item.image ? "" : "listeArticles_content--noimage")}>
                                                <h3 className="listeArticles_titre">{item.titre}</h3>
                                                <p className="listeArticles_texte">{item.description}</p>
                                                <p className="listeArticles_link"><span>{item.cta.title}</span></p>
                                            </div>
                                        </CLink>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
            }
        </>

    )

}
export default ListeArticles;