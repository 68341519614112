import React from 'react'

const Bandeau = ({ image = "", imageTexte = null }) => {

    return (
        <>
            {
                image && <div className="bandeau" style={{ backgroundImage: "url(" + image + ")" }}>
                    {
                        imageTexte && <img className='bandeau_imageTexte' src={imageTexte.src} alt={imageTexte.alt} />
                    }
                </div>
            }
        </>
    )
}
export default Bandeau;