import React, { createContext, useState } from 'react'

export const TutoContext = createContext()

export const TutoProvider = ({ children }) => {
    const [activeStep, setActiveStep] = useState(1)
    const [tutoOpen, setTutoOpen] = useState(localStorage.getItem('tuto-hidden') === null ? true : false);
    return (
        <TutoContext.Provider
            value={{ activeStep, setActiveStep, tutoOpen, setTutoOpen }}>
            {children}
        </TutoContext.Provider>
    )
}