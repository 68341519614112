import React from "react"

const InteractiveMap = ({
    submitForm,
    newInputValue,
    changeValue,
    lbl_recherche,
    placeholder,
    image_recherche: {src, alt},
    titre_image,
    activeStepClass,
    spotNumber,
    textStepTuto }) => {

    return (
        <div className={`map-campus layout ${activeStepClass}`}>
            <form className="input__container">
                <label>
                    {lbl_recherche}
                    <input
                        className="input"
                        type="texte"
                        value={newInputValue}
                        placeholder={placeholder}
                        onChange={changeValue} />
                    <button
                        onClick={submitForm}
                        className="input-submit">
                        <span className="checkmark"></span>
                    </button>
                </label>
            </form>
            {
                src &&
                <div className="map-campus-visual">
                    <h2>{titre_image}</h2>
                    <div className="map-campus-visual__container">
                        <img src={src} alt={alt} />
                        <div className={`hotspot ${spotNumber ? 'spot' : ''} spot-${spotNumber}`}>
                            <div className="hotspot-icon"></div>
                            <p className="hotspot-tooltip">Vous êtes ici</p>
                        </div>
                    </div>
                </div>
            }
            {
                textStepTuto &&
                <div className="tuto-explication__container">
                    <img src="/assets/img/tuto-arrow.png" alt="" />
                    <p>{textStepTuto}</p>
                </div>
            }
        </div>
    )
}
export default InteractiveMap;