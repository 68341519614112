import React, { useContext, useEffect, useState } from "react";

import "./sound-interface.scss";

import { SoundBackgroundContext } from "../sound-background/sound-background";
import Sound from "../../tools/Sound";
import { Link } from "react-router-dom";

export const SoundInterface = ({
	className,
	color,
	colorGradient,
	visuel,
	visuel_fixe,
	appearsOnPlaying,
	changeURL = false
}) => {
	const [srtOpen, setSrtOpen] = useState(false);
	const { playing, time, duration, tabAudio, currentTrack, play, pause, paused, prev, next } =
		useContext(SoundBackgroundContext);

	const [srt, setSrt] = useState([]);

	const [currentLine, setCurrentLine] = useState(0);

	useEffect(() => {
		let nextLine = null;

		srt.forEach((item, index) => {
			if (nextLine === null) {
				let start = Sound.convertTimecodeToSeconds(item.startTime);
				let end = Sound.convertTimecodeToSeconds(item.endTime);

				if (isNaN(start) !== "true" && isNaN(end) !== "true") {
					if (start <= time && end >= time) {
						nextLine = index;
					} else if (start > time && index > 0) {
						nextLine = index - 1;
					}
				}
			}
		});

		if (nextLine === null) {
			nextLine = srt.length;
		}

		setCurrentLine(nextLine);
	}, [time, srt]);

	useEffect(() => {
		if (tabAudio.length > 0 && currentTrack !== null) {
			setSrt(tabAudio[currentTrack].srt);
		}
	}, [tabAudio, currentTrack]);

	const getURL = (tab, id, sens) => {
		if (tab.length > 0 && id !== null) {
			if (tab[id + sens]) {
				return tab[id + sens].path;
			}
		}

		return "/";
	};

	const getSRT = (tab, id) => {
		if (tab.length > 0 && id !== null) {
			if (tab[id]) {
				return tab[id].srt.length;
			}
		}

		return 0;
	};

	return (
		<div className={className}>
			<div className={`soundInterface`}>
				{!srtOpen && (!paused || !visuel_fixe) && (
					<div className="soundInterface_visuel" style={{ backgroundImage: "url('" + visuel + "')" }}></div>
				)}
				{!srtOpen && paused && visuel_fixe && (
					<div
						className="soundInterface_visuel"
						style={{ backgroundImage: "url('" + visuel_fixe + "')" }}
					></div>
				)}
				{(!appearsOnPlaying || playing) && (
					<>
					<div className={`soundInterface_ui`}>
						<div className="soundInterface_box">
							{!changeURL && (
								<button
									className={`soundInterface_button soundInterface_button--prev ${
										tabAudio.length === 0 || currentTrack === 0
											? "soundInterface_button--disabled"
											: ""
									}`}
									onClick={() => prev()}
								></button>
							)}
							{changeURL && (
								<Link
									onClick={() => prev()}
									to={getURL(tabAudio, currentTrack, -1)}
									className={`soundInterface_button soundInterface_button--prev ${
										tabAudio.length === 0 || currentTrack === 0
											? "soundInterface_button--disabled"
											: ""
									}`}
								></Link>
							)}
						</div>
						<div className="soundInterface_box">
							<button
								className={`soundInterface_button soundInterface_button--${paused ? "pause" : "play"}`}
								onClick={() => (paused ? play() : pause())}
							></button>
						</div>
						<div className="soundInterface_box">
							{!changeURL && (
								<button
									className={`soundInterface_button soundInterface_button--next ${
										tabAudio.length === 0 || currentTrack >= tabAudio.length - 1
											? "soundInterface_button--disabled"
											: ""
									}`}
									onClick={() => next()}
								></button>
							)}
							{changeURL && (
								<Link
									onClick={() => prev()}
									to={getURL(tabAudio, currentTrack, 1)}
									className={`soundInterface_button soundInterface_button--next ${
										tabAudio.length === 0 || currentTrack >= tabAudio.length - 1
											? "soundInterface_button--disabled"
											: ""
									}`}
								></Link>
							)}
						</div>
						<div className="soundInterface_box">
							{getSRT(tabAudio, currentTrack) > 0 && (
								<button
									onClick={e => setSrtOpen(!srtOpen)}
									className={`soundInterface_button2 ${
										srtOpen ? "soundInterface_button2--current" : ""
									} soundInterface_button--srt`}
								>
									<span
										style={{ backgroundColor: srtOpen ? color : "" }}
										className="soundInterface_button2_top"
									></span>
									<span
										style={{ backgroundColor: srtOpen ? color : "" }}
										className="soundInterface_button2_middle"
									></span>
									<span
										style={{ backgroundColor: srtOpen ? color : "" }}
										className="soundInterface_button2_bottom"
									></span>
								</button>
							)}
						</div>
					</div>
					<div className="soundInterface_progressBar">
					{ time && duration ?
						<span style={{width: `${(time * 100)/duration}%`}}></span>
						:
						null
					}
					</div>
					</>
				)}
				{srtOpen && (
					<div className="soundInterface_srt">
						{srt.map((item, index) => {
							return (
								<>
									{currentLine === 0 && index === 0 && (
										<>
											<p class="soundInterface_srt_line--diff2">&nbsp;</p>
											<p class="soundInterface_srt_line--diff1">&nbsp;</p>
										</>
									)}
									{currentLine === 1 && index === 0 && currentLine === 1 && index === 0 && (
										<p class="soundInterface_srt_line--diff1">&nbsp;</p>
									)}
									<p
										key={index}
										className={`soundInterface_srt_line soundInterface_srt_line--diff${Math.abs(
											index - currentLine
										)}`}
									>
										{item.text}
									</p>
								</>
							);
						})}
					</div>
				)}
			</div>
		</div>
	);
};
