import React from 'react'

const Ariane = () => {

    return (
        <>
            {
                false && <div className="ariane">
                    <div className="layout7">
                        <ul className="ariane_liste">
                            <li className="ariane_item"><a href="https://wwww.google.fr">Accueil</a></li>
                            <li className="ariane_item"><span>Mon Quotidien</span></li>
                        </ul>
                    </div>
                </div>
            }
        </>
    )

}
export default Ariane;