import React from "react";

const Titre = ({ title }) => {

	return (
    <>
      {title && <h2 className="flex_titre">{title}</h2>}
    </>
	);
};

export default Titre;
