import React, { useState } from "react";

const Texte = ({ plus, text, text_supp, title }) => {
	const [isOpen, setOpen] = useState(false);

	return (
		<div className="flex_texte">
			<div className="wysiwig" dangerouslySetInnerHTML={{ __html: text }}></div>
			{text_supp && (
				<>
					{!isOpen && (
						<p className="flex_texte_more">
							<button onClick={() => setOpen(true)}>{plus}</button>
						</p>
					)}
					<div
						className={`wysiwig wysiwig--more ${isOpen ? "" : "wysiwig--hidden"}`}
						dangerouslySetInnerHTML={{
							__html: text_supp
								.replace(/<\/?span[^>]*>/g, "")
								.replace(/&nbsp;/g, "")
								.replace(" ", "")
						}}
					></div>
				</>
			)}
		</div>
	);
};
export default Texte;
