import React from 'react';
import {Helmet} from "react-helmet";

const Metatags = ( { title, description, og_fb_title, og_fb_desc, og_fb_img, og_twitter_title, og_twitter_desc, og_twitter_img } ) => {

    return (

        <Helmet>
            <title>{title}</title>
            <meta id="meta-description" name="description" content={description} />
            <meta property="og:url" content={window.location.href} />
            <meta property="og:site_name" content={title} />
            <meta property="og:locale" content="en_US" />
            <meta property="og:locale:alternate" content="fr_FR" />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={og_fb_desc} />
            <meta property="og:image" content={og_fb_img} />
            <meta property="og:image:secure_url" content={og_fb_img} />
            <meta property="og:image:width" content="166" />
            <meta property="og:image:height" content="88" />
            <meta property="og:image:alt" content={og_fb_title} />
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:title" content={og_twitter_title} />
            <meta name="twitter:description" content={og_twitter_desc} />
            <meta name="twitter:image" content={og_twitter_img} />
        </Helmet>

    );
}

export default Metatags;