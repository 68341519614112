import { saveState, loadState } from '../../Utils/loadState';
import { GLOBAL_UPDATE_GLOBAL, GLOBAL_UPDATE_ROUTES, GLOBAL_IS_LOADED, GLOBAL_IS_SCROLLLOCKED, GLOBAL_UPDATE_LANG } from "../action";

let initialState = {
	isLoaded: false,
	global: {},
	routes: {},
	lockScroll: false,
	lang: loadState('site-lang') || 'en'
};

/**
 * 
 * @param {*} state 
 * @param {*} action 
 */

export function globalReducer(state = initialState, action) {
	switch (action.type) {
		case GLOBAL_UPDATE_GLOBAL:
			return { ...state, global: action.value };
		case GLOBAL_UPDATE_ROUTES:
			return { ...state, routes: action.value };
		case GLOBAL_IS_LOADED:
			return { ...state, isLoaded: action.value };
		case GLOBAL_UPDATE_LANG:
			saveState('site-lang', action.value);
			document.querySelector('html').setAttribute('lang', action.value);
			return { ...state, lang: action.value };
		case GLOBAL_IS_SCROLLLOCKED:
			if (action.value === true) {
				document.documentElement.classList.add('scrollLocked');
				document.body.classList.add('scrollLocked');
				document.body.style.height = window.innerHeight + 'px';
			} else {
				document.documentElement.classList.remove('scrollLocked');
				document.body.classList.remove('scrollLocked');
				document.body.style.height = null;
			}
			return { ...state, lockScroll: action.value };
		default:
			return state;
	}
}
