class Sound {
    static getDuration = (duration, type) => {
        let step = parseInt(duration);
        if (isNaN(step)) {
            return "";
        }

        let minutes = Math.floor(step / 60);
        let secondes = step % 60;
        let secondesStr = (secondes < 10 ? "0" + secondes : "" + secondes);

        if (type === "long") {
            return minutes + "min " + secondesStr + "s";
        } else {
            return minutes + "'" + secondesStr;
        }
    }

    static getAllDuration = (playlist) => {
        let duration = 0;
        playlist.forEach(element => {
            duration += element.duree;
        });
        return this.getDuration(duration, "long");
    }

    static loadFiles = async (tab, callback) => {

        let { default: srtParser2 } = require("srt-parser-2");

        let myInit = {
            method: 'GET',
            mode: 'cors',
            cache: 'default'
        };

        let finalTab = [];

        for (let i = 0; i < tab.length; i++) {
            let item = tab[i];
            let srt = [];
            if (item.sous_titrage) {
                let response = await fetch(item.sous_titrage.src, myInit);
                let text = await response.text();
                let parser = new srtParser2();
                srt = parser.fromSrt(text);
            }
            finalTab.push({ mp3: item.mp3.src, srt, path: item.path })
        }

        if (callback) {
            callback(finalTab);
        }
    }

    static loadIntro = async (srtFile, callback) => {

        let { default: srtParser2 } = require("srt-parser-2");

        let myInit = {
            method: 'GET',
            mode: 'cors',
            cache: 'default'
        };

        let srt = [];
        if (srtFile && srtFile.src) {
            let response = await fetch(srtFile.src, myInit);
            let text = await response.text();
            let parser = new srtParser2();
            srt = parser.fromSrt(text);
            console.log(srtFile);
        }

        if (callback) {
            callback(srt);
        }
    }

    static convertTimecodeToSeconds = (timecode) => {
        let seconds = 0;
        let parts = timecode.split(":");

        if (parts.length === 3) {
            parts[2].split(",").join(".");
            seconds = parts[0] * 3600 + parts[1] * 60 + parseFloat(parts[2]);
        }

        return seconds;
    }
}

export default Sound;