import React from "react";
import { Link } from "react-router-dom";
import Sound from "../../tools/Sound";
import CLink from "../cLinks";

const Playlist = ({ playlist }) => {
    return (
        <div className="flex_playlist">
            <h2 className="flex_playlist_titre">{playlist.title}</h2>
            <div className="flex_playlist_contenus">
                <table>
                    <tbody>
                        {
                            playlist.liste.list.map((item, index, tab) => {
                                let color = item.collection.length > 0 ? item.collection[0].couleur.color : "black";
                                let color_gradient = item.collection.length > 0 ? item.collection[0].couleur.color_gradient : "black";
                                return (
                                    <tr key={index} className="flex_playlist_contenus_item">
                                        <td className="playlist_contenus_itemIndex"><strong>{index + 1}</strong>/{tab.length}</td>
                                        <td className="flex_playlist_contenus_itemTitre"><Link to={item.path} className="flex_playlist_contenus_itemButton"><div className="playlist_contenus_itemVisuel"><div className="playlist_contenus_itemVisuelBox"><img style={{ background: "linear-gradient(" + color + ", " + color_gradient + ")" }} {...item.image} alt="" /></div></div><p className="flex_playlist_contenus_itemLabel"><strong>Capsule audio {index + 1}/{tab.length}</strong>{item.title}</p></Link></td>
                                        <td className="flex_playlist_contenus_itemDuree"><span>{Sound.getDuration(item.duree)}</span></td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
            <p className="flex_playlist_button">
                <CLink {...playlist.cta} className="button button--type4" />
            </p>
        </div>

    )
}
export default Playlist;