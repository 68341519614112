import React from 'react'
import CLink from './link';

const OtherPushs = ({ pushs = [] }) => {

    return (
        <>
            {
                pushs && pushs.length > 0 && <div className="otherPushs">
                    {
                        pushs.length > 0 && <ul className="otherPushs_liste layout5">
                            {
                                pushs.map((item, index) => {
                                    return (
                                        <li key={index} className="otherPushs_item">
                                            <CLink url={item.cta.url} target={item.cta.target} internal={item.cta.internal}>
                                                {
                                                    item.image && <div className="otherPushs_visuel"><img src={item.image.src} alt={item.image.alt} /></div>
                                                }
                                                <div className="otherPushs_content">
                                                    <h3 className="otherPushs_titre">{item.titre}</h3>
                                                    <p className="otherPushs_texte">{item.description}</p>
                                                    <p className="otherPushs_link"><span>{item.cta.title}</span></p>
                                                </div>
                                            </CLink>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    }

                </div>
            }
        </>

    )

}
export default OtherPushs;