import React, { createContext, useCallback, useEffect, useState } from "react";
export const SoundBackgroundContext = createContext()
export const SoundBackgroundProvider = ({ children }) => {

    const [audio] = useState(new Audio());
    const [tabAudio, setTabAudio] = useState([]);
    const [currentTrack, setCurrentTrack] = useState(null);
    const [playing, setPlaying] = useState(false);
    const [time, setTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const [loop, setLoop] = useState(false);

    const [paused, setPaused] = useState(true);

    const start = (files, index = 0) => {
        setTabAudio(files);
        setCurrentTrack(index);
    }

    const next = useCallback(
        (forceLoop = false) => {
            if (loop || !forceLoop) {
                setCurrentTrack(currentTrack < tabAudio.length - 1 ? currentTrack + 1 : tabAudio.length - 1);
            } else {
                stop();
            }
        }, [currentTrack, tabAudio, audio]
    )

    const updateTime = useCallback(
        () => {
            setTime(audio.currentTime);
            setDuration(audio.duration);
        }, [audio]
    )

    useEffect(() => {
        audio.addEventListener("ended", next);
        audio.addEventListener("timeupdate", updateTime);

        return () => {
            audio.removeEventListener("ended", next);
            audio.removeEventListener("timeupdate", updateTime);
        }
    }, [audio, next]);

    useEffect(() => {
        if (tabAudio.length > 0 && currentTrack !== null) {
            play();
        }
    }, [tabAudio, currentTrack]);

    const play = () => {
        if (tabAudio.length > 0) {
            if (audio.currentTrack !== currentTrack) {
                audio.currentTrack = currentTrack;
                audio.src = tabAudio[currentTrack].mp3;
            }

            let promise = audio.play();
            if (promise !== undefined) {
                promise.then(() => {
                    setPaused(false);
                    setPlaying(true);
                }).catch(error => {
                    setPaused(true);
                    setPlaying(true);
                });
            }
        }
    }

    const pause = () => {
        if (audio.src) {
            audio.pause();
            setPaused(true);
        }
    }

    const stop = () => {
        if (audio.src) {
            audio.pause();
            audio.currentTime = 0;
            setPaused(true);
        }
    }

    const prev = () => {
        setCurrentTrack(currentTrack > 0 ? currentTrack - 1 : 0);
    }
    

    return (
        <SoundBackgroundContext.Provider value={{ start, play, pause, prev, next, playing, tabAudio, currentTrack, time, duration, paused, setCurrentTrack, stop, setLoop }}>
            {children}
        </SoundBackgroundContext.Provider>
    )
}
