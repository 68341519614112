import React, { useEffect, useRef } from "react";
import './image360.scss'

// LIB PANOLENS
import * as PANOLENS from "panolens";

export const Image360 = ({ imgUrl }) => {

    const refPanorama = useRef(null);

    const displayPanorama = () => {
        const panorama = new PANOLENS.ImagePanorama(imgUrl);
        const viewer = new PANOLENS.Viewer({
            container: refPanorama.current,
            controlBar: false,
            cameraFov: 110
        });
        viewer.add(panorama);
    }

    useEffect(() => {
        displayPanorama();
    }, []);

    return (
        <>
            <div ref={refPanorama} className="pano-image__container"></div>
        </>
    )
}