import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Slideshow = ({images}) => {
    let settings = {
        className: "slider",
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        dots: true,
        adaptiveHeight: true
    };

    return (
        <div className="flex_slideshow">
            <Slider {...settings}>
                {
                    images.map((img, index) => <div key={index}><img src={img.image.src} alt={img.image.alt} title={img.image.title} /></div>)
                }
            </Slider>
        </div>
    )
}
export default Slideshow;