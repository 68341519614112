import React, { useContext, useEffect } from "react";
import { useDispatch } from 'react-redux';
import { updateGlobalStorePage } from '../Store/action';
import { SoundBackgroundContext } from '../modules/sound-background/sound-background';
import useApi from '../Hooks/useApi';
import CLink from "../Components/cLinks";
import LoadPage from "../Components/loaders/loader";

const Template404 = () => {

	const [dataFetch, isLoaded] = useApi({ name: "404" });
  const { title, desc, link } = dataFetch;

	let dispatch = useDispatch();
	useEffect(() => {
		dispatch(updateGlobalStorePage('page-404'));
	}, [dispatch]);

	const { stop } = useContext(SoundBackgroundContext)
    useEffect(() => {
        stop();
    }, [stop]);

	const renderPage = () => (
		<>
			{
				title && desc && <div className="headerPage404">
					<div className="headerPage404_inner layout">
						<p className="headerPage404_logo"><a href="/"><img src="/assets/img/logo_transfo.svg" alt="Les voies de Transfo" /></a></p>
						<div className="headerPage404_other">
							<h1 className="headerPage404_title">{title}</h1>
							<CLink
								className="headerPage404_texte"
								url={link.url}
								internal={link.internal}
								target={link.target}
								title={link.title}
							>
								<span dangerouslySetInnerHTML={ { __html: desc } }></span>
							</CLink>
						</div>
					</div>
				</div>
			}
		</>
	)

	return isLoaded ? renderPage() : <LoadPage />;

};

export default Template404;