import React, { useContext, useEffect } from "react";
import useApi from '../Hooks/useApi';
import Metatags from '../Components/metas-tags/meta-tags';
import { useDispatch } from 'react-redux';
import { updateGlobalStorePage } from '../Store/action';
import CLink from "../Components/cLinks";
import LoadPage from "../Components/loaders/loader";
import { SoundBackgroundContext } from '../modules/sound-background/sound-background';

const TemplateHome = ({ _uid }) => {

    const [dataFetch, isLoaded] = useApi({ name: "pages", _uid });
    const { metas, content } = dataFetch;

    let dispatch = useDispatch();
    useEffect(() => {
        dispatch(updateGlobalStorePage('home'));
    }, [dispatch, isLoaded]); // isLoaded

    const { stop, setCurrentTrack } = useContext(SoundBackgroundContext)
    useEffect(() => {
        stop();
        setCurrentTrack(null);
    }, []);

    const renderPage = () => (
        <>
            {metas && <Metatags {...metas} />}

            {
                content && <div className="headerPageHome">
                    <div className="headerPageHome_inner layout2">
                        <p className="headerPageHome_logo"><img src={content.top.logo1.image.src} alt={content.top.logo1.image.alt} /></p>
                        <div className="headerPageHome_cols">
                            <div className="headerPageHome_col headerPageHome_col--1">
                                <p className="headerPageHome_colImage"><img src={content.top.logo2.image.src} alt={content.top.logo2.image.alt} /></p>
                                <p className="headerPageHome_colTexte" dangerouslySetInnerHTML={{ __html: content.top.logo2.message }}></p>
                            </div>
                            <div className="headerPageHome_col headerPageHome_col--2">
                                <p><img src={content.top.logo3.image.src} alt={content.top.logo3.image.alt} /></p>
                            </div>
                        </div>
                        <p className="headerPageHome_animation"><img src={content.main.gif.src} alt={content.main.gif.alt} /></p>
                        <p className="headerPageHome_button">
                            <CLink {...content.main.cta} className="button button--type1" />
                        </p>
                    </div>
                </div>
            }
        </>
    )

    return isLoaded ? renderPage() : <LoadPage />
}

export default TemplateHome;