import React, { useContext, useEffect } from "react";

// Hooks
import { useDispatch } from 'react-redux';
import { updateGlobalStorePage } from '../Store/action';
import { Link } from 'react-router-dom';
import useApi from '../Hooks/useApi';

// Components
import ComponentByJSON from '../Components/routes/component-from-json';
import Metatags from '../Components/metas-tags/meta-tags';
import Sound from "../tools/Sound";
import { SoundBackgroundContext } from '../modules/sound-background/sound-background';
import { SoundInterface } from "../modules/sound-interface/sound-interface";
import LoadPage from "../Components/loaders/loader";

const TemplateContentPage = ({ _uid }) => { // { _uid }


	//let dataFetch = require('../api/page.json');
	const [dataFetch, isLoaded] = useApi({ name: "posts", _uid });
	const { metas, content } = dataFetch;

	let dispatch = useDispatch();
	useEffect(() => {
		dispatch(updateGlobalStorePage('content-page'));
	}, [dispatch, isLoaded]); //

	const getColor = (key, content) => {
		return content.main.capsule.collection.length > 0 ? content.main.capsule.collection[0].couleur[key] : "black";
	}

	const { start, currentTrack, playing } = useContext(SoundBackgroundContext)
	useEffect(() => {
		try {
			if (content) {
				let capsule = content.main.capsule;
				let id = 0;
				let tab = capsule.liste_collection.list;

				for (let i = 0; i < tab.length; i++) {
					if (tab[i].path === capsule.path) {
						id = i;
						break;
					}
				}

				Sound.loadFiles(tab, finalTab => start(finalTab, id));
			}
		} catch {

		}
	}, [content]);

	const renderPage = () => (
		<>
			<Metatags {...metas} />
			{
				content && <div className="headerPageFlex" style={{ background: "linear-gradient(#010000, " + getColor("color_gradient", content) + " 35%, " + getColor("color", content) + ")" }}>
					<SoundInterface className={"headerPageFlex_soundInterface"} color={getColor("color", content)} colorGradient={getColor("color_gradient", content)} visuel={content.main.capsule.image.src} visuel_fixe={content.main.capsule.image_transparent.src} changeURL={true} />
					<div className="headerPageFlex_inner layout2">
						<div className="headerPageFlex_visuel" style={{ backgroundImage: "url('/assets/img/visuel_micro.png')" }}></div>
						<div className="headerPageFlex_logo">
							{
								content.main.capsule.collection.length > 0 && <Link to={content.main.capsule.collection[0].home_collections}><img src="/assets/img/logo_transfo.svg" alt="Les voies de Transfo" /></Link>
							}
							{
								content.main.capsule.collection.length === 0 && <img src="/assets/img/logo_transfo.svg" alt="Les voies de Transfo" />
							}
						</div>
					</div>
				</div>
			}

			{
				content && <div className="pageFlex">

					<div className="layout">
						<div className="flex_intro">
							{
								content.main.capsule.collection.length > 0 && <>
									<Link to={content.main.capsule.collection[0].retour_collection} className="flex_intro_back button--back"></Link>
									<p className="flex_intro_theme" style={{ color: getColor("color", content) }}>{content.main.capsule.collection[0].name}</p>
								</>
							}

							<p className="flex_intro_titre">{content.main.capsule.title}</p>
							<p className="flex_intro_infos">
								<span className="flex_intro_infos_author">{content.main.capsule.intervenant_nom} - {content.main.capsule.intervenant_fonction}&nbsp;</span>
								{content.main.capsule.lieu && <span className="flex_intro_infos_position">{content.main.capsule.lieu}&nbsp;</span>}
								<span className="flex_intro_infos_time">{Sound.getDuration(content.main.capsule.duree)}</span>
							</p>
						</div>

						{content && content.main.flexible && content.main.flexible.map((block) => ComponentByJSON(block))}
					</div>
				</div>
			}
		</>
	)

	return isLoaded ? renderPage() : <LoadPage />
}

export default TemplateContentPage;