import React, { useState } from 'react'

const Wysiwyg = ({ image = null, flexibles = [] }) => {

    const [videoPlaying, setVideoPlaying] = useState({});

    return (
        <div className="wysiwyg">
            <div className={image ? "layout6" : "layout8"}>
                <div className="wysiwyg_container">
                    {
                        image && <div className="wysiwyg_visuel"><img src={image.src} alt={image.alt} /></div>
                    }
                    {
                        flexibles && flexibles.length > 0 && <div className="wysiwyg_content">
                            {
                                flexibles.map((item, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            {
                                                item.bloc === "BlocWysiwyg" && <div dangerouslySetInnerHTML={{ __html: item.datas?.texte_wysiwyg }}></div>
                                            }
                                            {
                                                item.bloc === "BlocCitation" && <div className='wysiwyg_blockquote'>
                                                    <blockquote dangerouslySetInnerHTML={{ __html: item.datas?.texte }}></blockquote>
                                                    {
                                                        item.datas?.auteur && <h4>{item.datas?.auteur}</h4>
                                                    }
                                                    {
                                                        item.datas?.fonction && <p>{item.datas?.fonction}</p>
                                                    }
                                                </div>
                                            }
                                            {
                                                item.bloc === "BlocImage" && <p className={"wysiwyg_content_image " + (item.datas?.is_taille_reelle ? "truesize" : "")}><img src={item.datas?.image?.src} alt={item.datas?.image?.alt ? item.datas.image.alt : ""} />{item.datas?.legende && <span className="wysiwyg_content_legende">{item.datas?.legende}</span>}</p>
                                            }
                                            {
                                                item.bloc === "BlocVideo" && (item.datas?.file || item.datas?.code_youtube || item.datas?.code_vimeo) && <div className="wysiwyg_content_video"><button onClick={() => setVideoPlaying({ datas: item.datas })} className="wysiwyg_content_video_content"><div className="wysiwyg_content_video_visuelBox"><img className="wysiwyg_content_video_visuel" src={item.datas?.image_couverture?.src} alt={item.datas?.image_couverture?.alt ? item.datas.image_couverture.alt : ""} /></div><span className="wysiwyg_content_video_infos"><p className="wysiwyg_content_video_titre">{item.datas?.titre}</p><p className="wysiwyg_content_video_description" dangerouslySetInnerHTML={{ __html: item.datas?.description }}></p></span></button></div>
                                            }
                                        </React.Fragment >
                                    )
                                })
                            }
                        </div>
                    }
                </div>
            </div>

            {
                videoPlaying.datas && <VideoWysiwyg {...videoPlaying} closeMenu={() => setVideoPlaying({})} />
            }
        </div>
    )
}
export default Wysiwyg;

const VideoWysiwyg = ({ datas, closeMenu }) => {

    const closeOverlay = (e) => {
        if (e.target.classList.contains("videoWysiwyg")) {
            closeMenu();
        }
    }

    return (
        <div className="videoWysiwyg" onClick={e => closeOverlay(e)}>
            <div className="videoWysiwyg_content">
                <button className="videoWysiwyg_close" onClick={() => closeMenu()}></button>
                {
                    datas?.file && <video className="videoWysiwyg_video" playsInline src={datas?.file?.src} controls autoPlay></video>
                }

                {
                    datas?.code_youtube && (
                        !window.tarteaucitron.state.youtubeapi ? (
                            <Tac service="youtubeapi" />
                        ) : (
                            <iframe className="videoWysiwyg_video" width="560" height="315" src={"https://www.youtube.com/embed/" + datas?.code_youtube + "?autoplay=1"} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                        )
                    )
                }

                {
                    datas?.code_vimeo && (
                        !window.tarteaucitron.state.vimeo ? (
                            <Tac service="vimeo" />
                        ) : (
                            <iframe className="videoWysiwyg_video" src={"https://player.vimeo.com/video/" + datas?.code_vimeo + "?autoplay=1"} width="640" height="360" frameBorder="0" title="Vimeo video player" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
                        )
                    )
                }

                <div className="videoWysiwyg_infos">
                    <p className="videoWysiwyg_titre">{datas?.titre}</p>
                    <p className="videoWysiwyg_description" dangerouslySetInnerHTML={{ __html: datas?.description }}></p>
                </div>
            </div>
        </div>
    )
}

const Tac = ({ service }) => {

    return (
        <div dangerouslySetInnerHTML={{ __html: window.tarteaucitron.engage(service) }}></div>
    )
}