import React, { useContext, useEffect } from "react";

// Hooks
import { useDispatch } from 'react-redux';
import { updateGlobalStorePage } from '../Store/action';
import { Link } from 'react-router-dom';
import useApi from '../Hooks/useApi';

// Components
import Metatags from '../Components/metas-tags/meta-tags';
import { Image360 } from "../modules/image360/image360";
import LoadPage from "../Components/loaders/loader";
import { SoundBackgroundContext } from "../modules/sound-background/sound-background";

const TemplateVr = ({ _uid }) => { // { _uid }
    const [dataFetch, isLoaded] = useApi({ name: "pages", _uid });
    const { metas, content } = dataFetch;

    let dispatch = useDispatch();
    useEffect(() => {
        dispatch(updateGlobalStorePage('content-page'));
    }, [dispatch, isLoaded]); // isLoaded

    const { pause } = useContext(SoundBackgroundContext)
    useEffect(() => {
        pause();
    }, []);

    const renderPage = () => (
        <>
            {metas && <Metatags {...metas} />}
            {
                content &&
                <div className="template-vr__wrapper">
                    <div className="header-template-vr">
                        <h1 className="title">{content.top.titre}</h1>
                        <h2 className="subtitle">{content.top.sous_titre}</h2>
                    </div>
                    <Image360 imgUrl={content.top.image_360.src} />
                    <div className="template-vr-content__container layout">
                        <div className="template-vr-content-title__container">
                            <Link
                                to={content.top.page_retour.url}
                                className="arrow-redirect">
                            </Link>
                            <h3
                                className="template-vr-content-title-color"
                                style={{
                                    color: content.main.capsule.collection_couleurs ? content.main.capsule.collection_couleurs.color : ""
                                }}>
                                {content.main.capsule.collection_titre}
                            </h3>
                            <h2
                                className="template-vr-content-title">
                                {content.main.capsule.title}
                            </h2>
                        </div>
                        <div
                            className="template-vr-content-text"
                            dangerouslySetInnerHTML={{ __html: content.main.texte_descriptif }}>
                        </div>
                    </div>
                </div>
            }
        </>
    )

    return isLoaded ? renderPage() : <LoadPage />
}

export default TemplateVr;