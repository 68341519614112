import React from 'react'

const ActualitesCard = ({data}) => {

        return (
            <>
            {data &&
                <>
                    {
                        data.titre_rubrique_actualite &&
                        <h2 className='template-home-content-title left'>{data.titre_rubrique_actualite}</h2>
                    }
                    <a href={data.cta.url} target={data.cta.target} className="actualites-card">
                        <div className="actualites-card__image">
                            <img src={data.image.src} alt={data.image.alt} />
                        </div>
                        <div className="actualites-card__content">
                            <div className='actualites-card__content-titlesCtn'>
                                {
                                    data.titre &&
                                    <h3 className="actualites-card__content-title">{data.titre}</h3>
                                }
                                {
                                    data.sous_titre &&
                                    <h4 className="actualites-card__content-subtitle">{data.sous_titre}</h4>
                                }
                            </div>
                            <p className="actualites-card__content-text">
                                {data.texte}
                            </p>
                            <p className='actualites-card__content-date'>{data.date}</p>
                        </div>
                        
                    </a>
                </>
            }
            </>
        )
   
}
export default ActualitesCard;