import React, { useContext, useEffect } from "react";
import useApi from '../Hooks/useApi';
import Metatags from '../Components/metas-tags/meta-tags';
import { useDispatch } from 'react-redux';
import { updateGlobalStorePage } from '../Store/action';
import LoadPage from "../Components/loaders/loader";
import { SoundBackgroundContext } from '../modules/sound-background/sound-background';
import Wysiwyg from "../Components/blocks/wysiwyg";
import OtherPushs from "../Components/blocks/other-pushs";
import Intro from "../Components/blocks/intro";
import Ariane from "../Components/blocks/ariane";
import Bandeau from "../Components/blocks/bandeau";

const TemplateEdito = ({ _uid }) => {

    const [dataFetch, isLoaded] = useApi({ name: "pages", _uid });
    const { metas, content } = dataFetch;

    let dispatch = useDispatch();
    useEffect(() => {
        dispatch(updateGlobalStorePage('home'));
    }, [dispatch, isLoaded]); // isLoaded

    const { stop, setCurrentTrack } = useContext(SoundBackgroundContext)
    useEffect(() => {
        stop();
        setCurrentTrack(null);
    }, [stop, setCurrentTrack]);

    const renderPage = () => (
        <>
            {metas && <Metatags {...metas} />}

            {
                content && <div className={"templateEdito " + content.header?.header_couleur_theme}>
                    <Bandeau
                        image={content.header?.header_image?.src}
                        imageTexte={content.header?.header_texte}
                    />
                    <Intro
                        titre={content.header?.header_titre}
                        chapeau={content.header?.header_chapo}
                        texte={content.header?.header_texte_introduction}
                    />
                    <Wysiwyg
                        image={content.bloc_contenu?.image}
                        flexibles={content.bloc_contenu?.flexible}
                    />
                    <OtherPushs
                        pushs={content.pushs}
                    />
                </div>
            }

            <Ariane />
        </>
    )

    return isLoaded ? renderPage() : <LoadPage />
}

export default TemplateEdito;