import React, { useEffect } from "react";
import useApi from '../Hooks/useApi';
import Metatags from '../Components/metas-tags/meta-tags';
import { useDispatch } from 'react-redux';
import { updateGlobalStorePage } from '../Store/action';
import CLink from "../Components/cLinks";
import LoadPage from "../Components/loaders/loader";
import { Link } from "react-router-dom";

const TemplateChoix = ({ _uid }) => {

    const [dataFetch, isLoaded] = useApi({ name: "pages", _uid });
    const { metas, content } = dataFetch;

    // const [isClickable, setClickable] = useState(false);
    // const [selectedCase, setSelectedCase] = useState(null);

    let dispatch = useDispatch();
    useEffect(() => {
        dispatch(updateGlobalStorePage('content-page'));
    }, [dispatch, isLoaded]); // isLoaded

    // const selectCase = (selected) => {
    //     setSelectedCase(selected);
    //     setClickable(true);
    // }

    const renderPage = () => (
        <>
            {metas && <Metatags {...metas} />}

            {
                content && <div className="headerPageChoix">
                    <div className="headerPageChoix_inner layout2">
                        <div className="headerPageChoix_logo">
                            {
                                content.main.home_page && <Link to={content.main.home_page}><img src={content.top.general.logo.image.src} alt={content.top.general.logo.image.alt} /></Link>
                            }
                            {
                                !content.main.home_page && <img src={content.top.general.logo.image.src} alt={content.top.general.logo.image.alt} />
                            }
                        </div>
                        <div className="headerPageChoix_choix1">
                            <p className="headerPageChoix_choix1Titre">{content.main.texte_choix_visite}</p>
                            <div className="radios">
                                <CLink
                                    url={content.main.cta_choix1.url}
                                    internal={content.main.cta_choix1.internal}
                                    target={content.main.cta_choix1.target}
                                    title={content.main.cta_choix1.title}
                                    className={`button button--type5`}
                                />

                                <CLink
                                    url={content.main.cta_choix2.url}
                                    internal={content.main.cta_choix2.internal}
                                    target={content.main.cta_choix2.target}
                                    title={content.main.cta_choix2.title}
                                    className={`button button--type5`}
                                />
                            </div>
                        </div>
                        <div className="headerPageChoix_choix2">
                            <div className="headerPageChoix_animation"><img src={content.main.gif.src} alt={content.main.gif.alt} /></div>
                        </div>
                    </div>
                </div>
            }
        </>
    )

    return isLoaded ? renderPage() : <LoadPage />
}

export default TemplateChoix;