import React from 'react';
import Texte from '../flexible/texte';
import Visuel from '../flexible/visuel';
import Video from '../flexible/video';
import Playlist from '../flexible/playlist';
import Slideshow from '../flexible/slideshow';
import Cta from '../flexible/cta';
import Cta360 from '../flexible/cta360';
import Titre from '../flexible/titre';

/**
 * Déclaration de la corresspondance nom reçu / nom du composant
 *
 */
const DynamicComponents = {
  "BlocTitre" : Titre,
	"BlocText" : Texte,
	"BlocImage" : Visuel,
	"BlocVideo" : Video,
	"BlocPlaylist" : Playlist,
	"BlocCarrousel" : Slideshow,
	"BlocCta" : Cta,
	"BlocCta360": Cta360
};

/**
 * ## Generation des composants dynamiquement suivant JSON
 * @param {object} block
 * cf : https://www.storyblok.com/tp/react-dynamic-component-from-json
 */
const ComponentByJSON = (block) => {
	if (typeof DynamicComponents[block.bloc] !== 'undefined') {
		return React.createElement(DynamicComponents[block.bloc], {
			key: block._uid,
			...block.datas
		});
	}
	// component doesn't exist yet
	return null;
};
export default ComponentByJSON;