import React, {useEffect, useCallback, useRef} from 'react';

const CanvasPlayer = ({ videoSrc }) => {
  let canvas;
  let context;

  const video = useRef(null);
  const initCanvas = useCallback((node) => {
    if (node === null) return;
    context = node.getContext('2d');
    canvas = node;
  }, []);

  const updateCanvas = () => {
    if (video.current) {
      if (video.current.clientWidth > 0 && video.current.clientHeight > 0 && canvas.parentElement.clientHeight > 0 && canvas.parentElement.clientWidth > 0) {
        video.current.crossOrigin = "Anonymous";
        canvas.width = 1200;
        canvas.height = 1200;
        context.drawImage(video.current, 0, 0, video.current.clientWidth, video.current.clientHeight, 0, 0, canvas.width, canvas.height);
        let imageData = context.getImageData(0, 0, canvas.width, canvas.height);
        for (let i = 0; i < imageData.data.length; i += 4) {
          imageData.data[i + 3] = imageData.data[i];
        }
        context.putImageData(imageData, 0, 0);
      }
      requestAnimationFrame(updateCanvas);
    }
  };

  useEffect(() => {
    if (video.current === null || canvas === null) return;
    video.current.muted = true;
    updateCanvas();
  }, [video, canvas]);

  return (
    <>
      <canvas id="canvas" ref={initCanvas}></canvas>
      <video id="video" ref={video} muted loop autoPlay playsInline controls>
        <source src={videoSrc.src} type={videoSrc.type}/>
      </video>
    </>
  )
};

export default CanvasPlayer;
