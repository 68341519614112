import React from 'react'

const Intro = ({ titre = "", chapeau = "", texte = "" }) => {

    return (
        <div className="intro">
            <div className="layout5">
                <h1 className="intro_titre">{titre}</h1>
                <h2 className="intro_chapeau">{chapeau}</h2>
                <p className="intro_texte">{texte}</p>
            </div>
        </div>
    )

}
export default Intro;