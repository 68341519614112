import React, { useState, useEffect } from "react";
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const PlayerIntro = ({ url }) => {
  
  const [audio] = useState(new Audio(url));
  const [playingAudio, setPlayingAudio] = useState(false);
  const [currentTime, setCurrentTime] = useState("00:00");
  const [progressTime, setProgressTime] = useState(0);
  const toggleAudio = () => setPlayingAudio(!playingAudio);
  
  useEffect(() => {
        playingAudio ? audio.play() : audio.pause();
        if (playingAudio) {
            const interval = setInterval(() => {
                setProgressTime(Math.floor((audio.currentTime * 100)/audio.duration))
                let minutes = "0"+parseInt(audio.currentTime / 60, 10);
                let seconds = "0"+parseInt(audio.currentTime % 60);
                setCurrentTime(minutes + ":" + seconds.slice(-2));
            }, 1000);
            return () => {
                clearInterval(interval)
            };
        }
    }, [playingAudio]);

  useEffect(() => {
    audio.addEventListener('ended', () => setPlayingAudio(false));
    return () => {
        audio.removeEventListener('ended', () => setPlayingAudio(false));
    };
    }, []);
    
  return (
    <div className="player">
        <button onClick={toggleAudio}>
           <>
                <div className="player-playBtn">
                    <CircularProgressbarWithChildren value={playingAudio ? progressTime : 100} strokeWidth={3} styles={buildStyles({pathColor:'white', trailColor:'transparent'})}>
                        {playingAudio ? 
                        <>
                            <img className="micro" src="/assets/img/visuel_micro.png"/>
                            <p className="timer">{currentTime}</p>
                        </>
                        :
                        <>
                            <svg id="Picto_Play" data-name="Picto/Play" xmlns="http://www.w3.org/2000/svg" width="40" height="50" viewBox="0 0 19.76 24.32">
                                <path id="Fill_1" data-name="Fill 1" d="M18.866,13.8,3.156,23.98A2.032,2.032,0,0,1,0,22.34V1.98A2.032,2.032,0,0,1,3.156.34l15.71,10.18a1.951,1.951,0,0,1,0,3.279" transform="translate(0 0)" fill="#fff"/>
                            </svg>
                            <img className="Intro_play" src="/assets/img/intro_btn.png"/>
                        </>
                        }
                    </CircularProgressbarWithChildren>
                </div>
                <div className="player-introBtn">
                {playingAudio ? 
                    <p>Passer l'intro</p>
                :
                    <p className="Intro_play">Intro</p>
                }
                </div>
            </>
            
        </button>
    </div>
  );
};

export default PlayerIntro;