import React, { useContext } from 'react'

import { TutoContext } from '../../Contexts/tutoContext'




const OverlayTuto = () => {
    const { activeStep, setActiveStep, setTutoOpen } = useContext(TutoContext)
    const steps = [1, 2, 3];

    const checkClick = e => {
        let percent = e.pageX / e.target.clientWidth;
        if (percent < 0.5 && activeStep !== steps[0]) {
            setActiveStep(activeStep - 1)
        } else if (percent >= 0.5 && activeStep !== steps[steps.length - 1]) {
            setActiveStep(activeStep + 1)
        }
    }

    const closeTuto = () => {
        setTutoOpen(false);
        localStorage.setItem('tuto-hidden', true);
        console.log('ok')
    }

    return (
        <div className="overlay-tuto__container">
            <span onClick={() => closeTuto()} className="overlay-tuto-close"></span>
            {
                steps.length > 0 && <ul className="tuto-steps">
                    <li className={"overlay-tuto-buttonBox"}><button className={`overlay-tuto-button overlay-tuto-button--prev ${activeStep === steps[0] ? "overlay-tuto-button--hidden" : ""}`} onClick={() => setActiveStep(activeStep - 1)}></button></li>
                    {
                        steps.map((step, key) => {
                            return (
                                <li
                                    key={key}
                                    className={`step ${activeStep === step ? 'active' : ''}`}
                                    onClick={() => setActiveStep(step)}>
                                    {step}
                                </li>
                            )
                        })
                    }
                    <li className={"overlay-tuto-buttonBox"}><button className={`overlay-tuto-button overlay-tuto-button--next ${activeStep === steps[steps.length - 1] ? "overlay-tuto-button--hidden" : ""}`} onClick={() => setActiveStep(activeStep + 1)}></button></li>
                </ul>
            }
            <span className="overlay-tuto" onClick={e => checkClick(e)}></span>
        </div>
    )
}
export default OverlayTuto;
